import mainServices from '@/services/main'
export default {
  data () {
    return {
      allCities: [],
      allAreas: []
    }
  },
  methods: {
    getAllCities () {
      mainServices.getAllCities().then(res => {
        this.allCities = res.data.data
      })
    },
    // getAllCities () {
    //   mainServices.getAllContries().then(res => {
    //     this.allCountries = res.data.data
    //   })
    // }
    getAllAreas (id = null) {
      mainServices.getAllAreas(id).then(res => {
        this.allAreas = res.data.data
      })
    }
  },
  created () {
    // this.getAllCountries()
    console.log('created')
    this.getAllCities()
  }
}
