<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
      بيانات التاجر
    </dashboard-page-title>
    <b-row>
          <b-col md="12">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات التاجر</h5>
                </template>
                <b-row>
                  <b-col  cols="6"
                  >
                    <input-form
                        class="mb-3 joining-label"
                        v-model="merchant.user.name"
                        validate="required"
                        name="id"
                        :disabled="$route.params.id ? true : false"
                        placeholder="أدخل الاسم" label="الإسم"
                        :readonly="$route.params.id ? true : false"
                    />
                  </b-col>
                  <b-col  cols="6"
                  v-if="$route.params.id ? true : false"
                  >
                    <input-form
                        class="mb-3 joining-label"
                        v-model="merchant.commision_percentage"
                        validate="required"
                        name="id"
                        placeholder="أدخل العمولة" label="العمولة"
                    />
                  </b-col>
                  <b-col  cols="6" >
                    <input-form
                        class="mb-3 joining-label"
                        v-model="merchant.user.email"
                        validate="required"
                        name="email"
                        :disabled="$route.params.id ? true : false"
                        placeholder="أدخل البريد الإلكترونى" label="البريد الإلكترونى"
                        :readonly="$route.params.id ? true : false"
                        type="email"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="merchant.user.phone"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="$route.params.id ? true : false"
                        placeholder="Ex: 0109564546" label="رقم الجوال"
                        :readonly="$route.params.id ? true : false"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="merchant.user.national_id"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="$route.params.id ? true : false"
                        placeholder="Ex: 454846511154" label="رقم الهوية"
                        :readonly="$route.params.id ? true : false"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <!-- <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> روابط المتجر</h5>
                </template>
                <b-row >
                  <b-col md="6" class="mb-4" >
                    <input-form
                        class="mb-1 joining-label"
                        v-modal="merchant.social_media_links.facebook"
                        :disabled="$route.params.id ? true : false"
                        :placeholder="$t('main.facebook')" :label="$t('main.facebook')"
                        :readonly="$route.params.id ? true : false"
                    />
                  </b-col>
                  <b-col md="6" class="mb-4" >
                    <input-form
                        class="mb-1 joining-label"
                        v-modal="merchant.social_media_links.twitter"
                        :disabled="$route.params.id ? true : false"
                        :placeholder="$t('main.twitter')" :label="$t('main.twitter')"
                        :readonly="$route.params.id ? true : false"
                    />
                  </b-col>
                  <b-col md="6" class="mb-4">
                    <input-form
                        class="mb-1 joining-label"
                        v-modal="merchant.social_media_links.instagram"
                        :disabled="$route.params.id ? true : false"
                        :placeholder="$t('main.instagram')" :label="$t('main.instagram')"
                        :readonly="$route.params.id ? true : false"
                    />
                  </b-col>
                </b-row>
              </b-card> -->
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-store"></i> معلومات المتجر</h5>
                </template>
                <b-row>
                  <b-col md="6" class="mb-3">
                    <label >
                      المدينة
                    </label>
                    <main-select
                      :reduce="(city) => city.id"
                      :options="allCities"
                      v-model="merchant.store_city_id"
                      label="name"
                      class="flex-grow-1 mb-0 w-100"
                      placeholder="اختر المدينة"
                      dir="rtl"
                      validate="required"
                      :disabled="$route.params.id ? true : false"
                    />
                </b-col>
                <b-col md="6" class="mb-3">
                  <label >
                    المنطقة
                  </label>
                  <main-select
                    :reduce="(area) => area.id"
                    :options="allAreas"
                    :disabled="merchant.store_city_id === null || $route.params.id"
                    v-model="merchant.store_area_id"
                    label="name"
                    class="flex-grow-1 mb-0 w-100"
                    placeholder="اختر المنطقة"
                    dir="rtl"
                    validate="required"
                  />
                </b-col>
                  <b-col  cols="12">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="merchant.store_name"
                        :disabled="$route.params.id ? true : false"
                        placeholder="أدخل الاسم" label="الاسم"
                    />
                  </b-col>
                  <b-col md="12">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="merchant.store_address"
                        :validate="'required'"
                        name="id"
                        :disabled="$route.params.id ? true : false"
                        label="العنوان"
                        placeholder="العنوان"
                    />
                  </b-col>

                  <!-- <b-col md="12">
                    <textarea-form placeholder="الوصف" v-model="merchant.policies" label="الشروط و الأساسيات" :readonly="$route.params.id ? true : false" :disabled="$route.params.id ? true : false" name="policies" validate="required"></textarea-form>
                  </b-col> -->
                </b-row>
              </b-card>
              <!-- <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i>  الصور</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="شعار المتجر"
                        nameOfImage="image.jpg"
                        @cropper-save="saveLogoImage"
                        :progressLoading="progressLogo"
                        :multi="false"
                        :imageUrl="merchant.logo"
                        :readonly="$route.params.id ? true : false"
                    />
                  </b-col>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="غلاف المتجر"
                        nameOfImage="image.jpg"
                        @cropper-save="saveCoverImage"
                        :progressLoading="false"
                        :showProgress="true"
                        :multi="false"
                        :readonly="$route.params.id ? true : false"
                        :imageUrl="merchant.cover"
                    />
                  </b-col>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="السجل التجارى"
                        nameOfImage="image.jpg"
                        @cropper-save="saveCommercialRegistry"
                        :progressLoading="false"
                        :showProgress="true"
                        :multi="false"
                        :readonly="$route.params.id ? true : false"
                        :imageUrl="merchant.commercial_registry"
                    />
                  </b-col>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="البطاقة الضريبية"
                        nameOfImage="image.jpg"
                        @cropper-save="saveVatCertificate"
                        :progressLoading="false"
                        :showProgress="true"
                        :multi="false"
                        :readonly="$route.params.id ? true : false"
                        :imageUrl="merchant.vat_certificate"
                    />
                  </b-col>
                </b-row>
              </b-card> -->
            </div>
          </b-col>
          <b-col md="3">
            <template
            >
              <!-- <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  النشر
                </h5>
              </template> -->
              <div v-if="loadingSubmitForm ">
                <b-button variant="primary" class="w-100" disabled>
                  <spinner-loading text="يتم الحفظ"></spinner-loading>
                </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                  <b-button variant="primary"  class="w-100" type="submit"
                    @click="submitForm"
                  > حفظ </b-button>
              </div>
            </template>
          </b-col>
        </b-row>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import merchants from '../services/marketPlace'

import citiesAndCountriesMixin from '@/mixins/countriesAndCities'

export default {
  components: { },
  mixins: [citiesAndCountriesMixin],
  mounted () {
    core.index()
    if (this.$route.params.id) {
      merchants.getStore(this.$route.params.id).then(res => {
        this.merchant = {
          ...res.data,
          store_city_id: res.data.city.id,
          store_area_id: res.data.area.id,
          store_address: res.data.address,
          store_name: res.data.name
        }
      })
    }
  },
  data () {
    return {
      merchant: {
        about: '',
        address: '',
        area: {
          id: '',
          name: ''
        },
        store_area_id: null,
        store_city_id: null,
        bank_account_name: null,
        bank_account_number: null,
        commision_percentage: 0,
        bank_name: null,
        city: {
          id: '',
          name: ''
        },
        commercial_registry: '',
        cover: '',
        created_at: null,
        description: '',
        is_featured: false,
        logo: '',
        name: '',
        store_name: '',
        store_address: '',
        policies: '',
        seo_description: '',
        seo_title: '',
        social_media_links: {
          facebook: '',
          instagram: '',
          twitter: ''
        },
        status: '',
        url: '',
        vat_certificate: '',
        vat_no: null,
        user: {
          avatar: '',
          country_code: '',
          email: '',
          id: '',
          name: '',
          national_id: '',
          phone: '',
          preferred_date_type: '',
          status: ''
        }
      }
    }
  },
  methods: {
    submitForm () {
      this.loadingSubmitForm = true
      const data = {
        name: this.merchant.user.name,
        phone: this.merchant.user.phone,
        national_id: this.merchant.user.national_id,
        store_name: this.merchant.store_name,
        store_address: this.merchant.store_address,
        store_city_id: this.merchant.store_city_id,
        store_area_id: this.merchant.store_area_id,
        country_code: '966',
        email: this.merchant.user.email
      }
      if (this.$route.params.id) {
        merchants.updateCommission(
          { commision_percentage: this.merchant.commision_percentage },
          this.$route.params.id
        ).then(res => {
          core.showSnackbar('success', res.data.message)
          this.$router.push({ name: 'merchant' })
        }).finally(() => {
          this.loadingSubmitForm = false
        })
      } else {
        merchants.saveStore(data).then(res => {
          core.showSnackbar('success', this.$t('main.addedSuccessfully'))
          this.$router.push({ name: 'merchant' })
        }).finally(() => {
          this.loadingSubmitForm = false
        })
      }
    }
  },
  watch: {
    'merchant.store_city_id': function (val) {
      if (val) {
        this.getAllAreas(val)
      }
    }
  }

}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
</style>
